import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public password: string;
  public username: string;
  public showSpinner = false;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  public login(): void {
    this.userService.login(this.username, this.password)
    .subscribe(authenticated => {
      if (!authenticated) {
        alert('Invalid credentials');
      }
    });
  }

}
