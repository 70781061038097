import { AuthService } from './auth.service';
import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private BASE_ROUTE = environment.apiUrl + 'user/';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post(this.BASE_ROUTE + 'login', {
      username,
      password
    }).pipe(map(
      (res: {user: string, token: string}) => {
        this.authService.setToken(res.token);
        return true;
      }, err => {
        console.error('Credentials invalid');
        return false;
      })
    );
  }

  logout(): void {
    this.authService.removeToken();
  }
}
