import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hbc-sponsoring';

  constructor(
    private authService: AuthService
  ) {}

  public isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
