<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>Application Title</h1>
    <span class="menu-spacer"></span>
    <div>
      <a mat-button [routerLink]="'/login'"> Login </a>
    </div>
  </mat-toolbar-row>

</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/login'" *ngIf="!isAuthenticated()"> Login </a>
      <a mat-list-item [routerLink]="'/otherthings'" *ngIf="isAuthenticated()"> Other Things </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 88vh;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>